import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { storeToRefs } from 'pinia';
import { UiLinkAsButton, UiButton, UiStyles, UiLink, UiDropDown, UiList, UiListMenuItem, UiListItemLine, UiLogo, UiIconName, UiIconTemplate, UiHeader, useAdaptive } from '@frontend/ui-kit';
import { AN, AT, AR, useAccess } from '~/assets/mixins/accessMixin';
import useSettingsStore from '~/stores/settingsStore';
import MobileMenu from '~/components/header/MobileMenu.vue';
import useDevStore from '~/stores/devSettingsStore';
import useUserStore from '~/stores/userStore';
import ServicesVersions from './ServicesVersions.vue';
import Support from './SupportChat.vue';
import Reference from './Reference.vue';
import Locale from './Locale.vue';
import ReleaseUpdates from './ReleaseUpdates.vue';
import Profile from './Profile.vue';
import RecentPages from './RecentPages.vue';
var LINE = {
  visible: true
};
export default defineComponent({
  components: {
    MobileMenu,
    UiLogo,
    ServicesVersions,
    UiListMenuItem,
    Profile,
    UiListItemLine,
    UiButton,
    UiLinkAsButton,
    UiList,
    UiLink,
    UiHeader,
    UiDropDown,
    ReleaseUpdates,
    Reference,
    Support,
    Locale,
    UiIconTemplate
  },
  setup() {
    var {
      can,
      canByRole
    } = useAccess();
    var {
      loggedIn
    } = storeToRefs(useUserStore());
    var settingsStore = useSettingsStore();
    var devStore = useDevStore();
    var {
      isMobile
    } = useAdaptive();
    function filterVisible(elements) {
      return elements.filter(_ref => {
        var {
          visible
        } = _ref;
        return visible;
      });
    }
    function filterAndAddLine(elements) {
      return elements.map(filterVisible).filter(g => g.length).flatMap((g, index) => index ? [LINE, ...g] : g);
    }
    var analyticsItems = computed(() => filterVisible([{
      locale: 'nav.dashboard',
      to: '/dashboards',
      visible: can(AN.Analytics, AT.Menu)
    }, {
      locale: 'nav.report_results',
      to: '/reports',
      visible: can(AN.DailyReport, AT.Menu)
    }, {
      locale: 'nav.billing',
      to: '/billing',
      visible: can(AN.Billing, AT.Menu)
    }]));
    var menuItems = computed(() => filterAndAddLine([[{
      locale: 'nav.products',
      href: '/old/product',
      visible: can(AN.Product, AT.Menu)
    }, {
      locale: 'nav.prod_cats',
      href: '/old/product-category',
      visible: can(AN.ProductCategory, AT.Menu)
    }, {
      locale: 'nav.equipment_and_posm',
      to: '/equipment-and-posm',
      visible: canByRole([AR.Admin, AR.SeniorEditor, AR.Supervisor, AR.Support])
    }], [{
      locale: 'nav.stores',
      to: '/stores',
      visible: can(AN.Store, AT.Menu)
    }, {
      locale: 'nav.routes',
      to: '/routes',
      visible: settingsStore.showRoutes && can(AN.Store, AT.Menu)
    }, {
      locale: 'nav.segments',
      to: '/segments',
      visible: can(AN.Store, AT.Menu)
    }, {
      locale: 'nav.retailers',
      to: '/retailers',
      visible: can(AN.Retailer, AT.Menu)
    }, {
      locale: 'countries.countries',
      to: '/countries',
      visible: can(AN.Country, AT.Menu)
    }, {
      locale: 'nav.regions',
      to: '/regions',
      visible: can(AN.Region, AT.Menu)
    }, {
      locale: 'nav.cities',
      to: '/cities',
      visible: can(AN.City, AT.Menu)
    }], [{
      locale: 'nav.areas',
      to: '/areas',
      visible: can(AN.Areas, AT.Menu)
    }, {
      locale: 'nav.teams',
      href: '/old/team',
      visible: can(AN.Team, AT.Menu)
    }], [{
      locale: 'nav.metrics',
      to: '/metrics',
      visible: can(AN.Metrics, AT.Menu)
    }, {
      locale: 'nav.planogramm',
      to: '/planogramm',
      visible: settingsStore.showPlanogramm && can(AN.Plan, AT.Menu)
    }, {
      locale: 'nav.planned_metrics',
      to: '/planned-metrics',
      visible: can(AN.Plan, AT.Menu)
    }, {
      locale: 'nav.planned_width',
      href: '/old/kpi/assortment',
      visible: can(AN.Plan, AT.Menu)
    }, {
      locale: 'nav.planned_photos',
      href: '/old/kpi/photo',
      visible: can(AN.Plan, AT.Menu)
    }, {
      locale: 'nav.planned_shelf',
      href: '/old/kpi/sos',
      visible: can(AN.Plan, AT.Menu)
    }, {
      locale: 'nav.assortment_matrices',
      to: '/plans/assortment',
      visible: can(AN.Plan, AT.Menu)
    }, {
      locale: 'nav.assortment_type',
      to: '/plans/assortment-type',
      visible: can(AN.PlanType, AT.Menu)
    }, {
      locale: 'nav.price-standards',
      to: '/price-standards',
      visible: can(AN.PriceStandards, AT.Menu)
    }], [{
      locale: 'nav.task_templates',
      to: '/tasks',
      visible: can(AN.Tasks, AT.Menu)
    }, {
      locale: 'nav.scene_groups',
      to: '/scene-groups',
      visible: can(AN.SceneType, AT.Menu)
    }, {
      locale: 'sales_locations.sales_locations',
      to: '/sales-locations',
      visible: can(AN.SceneType, AT.Menu)
    }, {
      locale: 'nav.shelf_share_formulas',
      href: '/old/share-exp',
      visible: can(AN.ShareExp, AT.Menu)
    }], [{
      locale: 'nav.periods',
      to: '/periods',
      visible: can(AN.Periods, AT.Menu)
    }], [{
      locale: 'nav.visit_recalc',
      to: '/mass-recount',
      visible: devStore.dev.massRecount && can(AN.Visit, AT.Menu)
    }], [{
      locale: 'nav.imports',
      to: '/imports',
      visible: canByRole([AR.Admin, AR.DataAdmin, AR.SeniorEditor, AR.Supervisor, AR.Support])
    }]]));
    var settingsItems = computed(() => filterAndAddLine([[devStore.dev.spa ? {
      locale: 'SPA DEV',
      to: '/spa',
      visible: true
    } : {}], [{
      locale: 'nav.portal_settings',
      href: '/old/settings',
      visible: can(AN.Settings, AT.Menu)
    }, {
      locale: 'nav.mobile_settings',
      to: '/settings/mobile',
      visible: can(AN.AppSettings, AT.Menu)
    }, {
      locale: 'nav.integrations',
      href: '/old/package',
      visible: canByRole(AR.Admin)
    }], [{
      locale: 'nav.lots',
      href: '/old/lot',
      visible: can(AN.Lot, AT.Menu)
    }], [{
      locale: 'nav.users',
      href: '/users',
      visible: can(AN.User, AT.Menu)
    }, {
      locale: 'nav.roles',
      href: '/old/rbac',
      visible: can(AN.Rbac, AT.Menu)
    }], [{
      locale: 'nav.related_lists',
      href: '/old/unified-list',
      visible: can(AN.UnifiedList, AT.Menu)
    }, {
      locale: 'nav.share_files',
      href: '/old/download',
      visible: can(AN.Download, AT.Menu)
    }]]));
    var pages = computed(() => filterVisible([{
      locale: 'nav.sfa_tasks',
      to: '/sfa-tasks',
      visible: settingsStore.showSfaTasks && can(AN.Tasks, AT.Menu)
    }, {
      locale: 'nav.visits',
      to: '/visits',
      visible: can(AN.Visit, AT.Menu)
    }, {
      locale: 'nav.photos',
      to: '/photos',
      visible: can(AN.Visit, AT.Menu) && !canByRole(AR.Merch)
    }, {
      locale: 'nav.recent',
      visible: true,
      component: RecentPages
    }, {
      locale: 'nav.analytics',
      visible: analyticsItems.value.length,
      items: analyticsItems.value
    }, {
      locale: 'nav.menu',
      visible: menuItems.value.length,
      items: menuItems.value
    }, {
      locale: 'nav.settings',
      visible: settingsItems.value.length,
      items: settingsItems.value
    }]));
    return {
      UiStyles,
      pages,
      loggedIn,
      UiIconName,
      isMobile
    };
  }
});